import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import invariant from "tiny-invariant";

import moment from "moment";
import { useAccessControl } from "../../app/use-access-control";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { useCenterConstants, type SubServiceDictionary } from "../use-center";
import { useFamilyConstants } from "../use-families";
import { inquiryQueryKeys } from "./query-keys";
import type { Inquiry, InquiryFormData, InquiryPayload, InquiryResponse, InquiryStage } from "./types";
import { useInquiryConstants } from "./use-inquiry-constants";

const transformResponseToFormData = (data: InquiryResponse, subServicesDict: SubServiceDictionary) => {
  const subjects = subServicesDict?.[data.program.value]?.subjects || [];

  const tests = subServicesDict?.[data.program.value]?.tests || [];
  const totalLength = subjects.length + tests.length;

  const subjectOrTest = data.subject?.value ? "subjectId" : "testId";
  const subjectOrTestId = data.subject?.value || data.test?.value;

  const selectedSubjectOrTest = subjectOrTestId ? `${subjectOrTestId}::${subjectOrTest}` : "";

  const localDateTime = moment.utc(`${data.inquiryDate}T${data.inquiryTimeReceived}Z`).local();

  return {
    id: data.id,
    family: data.family,
    familyId: data.family.id,
    inquirer: data.inquirer,
    inquirerId: data.inquirer.id,
    student: data.student,
    studentId: data.student.id,
    relationshipId: data.relationshipId,
    selectedSubService: `${data.program.value}::${totalLength}`,
    selectedSubjectOrTest,
    serviceId: data.service?.value,
    programId: data.program?.value,
    subjectId: data.subject?.value,
    testId: data.test?.value,
    inquiryDate: localDateTime.format("YYYY-MM-DD"),
    inquiryTimeReceived: localDateTime.format("HH:mm"),
    sourceId: data.source?.value,
    referredById: data.referredBy?.value,
    referredByOther: data.referredByOther,
    aeFee: {
      discountTypeId: data.aeFee?.discountType?.value,
      discountValue: data.aeFee?.discountValue,
      baseFee: data.aeFee?.baseFee,
      discountReasonId: data.aeFee?.discountReason?.value ?? "",
      discountReasonOther: data.aeFee?.discountReasonOther ?? ""
    },
    objections: data.objections?.filter(value => value.trim().length > 0),
    standardHourlyTuitionQuoted: data.standardHourlyTuitionQuoted,
    privateHourlyTuitionQuoted: data.privateHourlyTuitionQuoted,
    durationMinutes: data.durationMinutes,
    notes: data.notes,
    takenBy: {
      id: data.takenBy.externalId,
      givenName: data.takenBy.firstName,
      surname: data.takenBy.lastName
    },
    takenById: data.takenBy.externalId,
    callbackSpecialQuoted: data.callbackSpecialQuoted
  } as InquiryFormData;
};

export const useInquiry = (inquiryId?: string) => {
  const { activeCenterId } = useAccessControl();
  const { getToken } = useAccessToken();
  const { activeConstants } = useCenterConstants(activeCenterId);
  const {
    chainableFieldsDict: { subServicesDict }
  } = activeConstants;

  const hasSubServices = !!Object.keys(subServicesDict).length;

  return useQuery({
    queryKey: inquiryQueryKeys.detail(activeCenterId, inquiryId),
    enabled: Boolean(inquiryId) && hasSubServices,
    queryFn: async () => {
      invariant(inquiryId, "inquiryId is required");
      return getInquiry(await getToken(), activeCenterId, inquiryId);
    },
    select: data => transformResponseToFormData(data, subServicesDict)
  });
};

export const useInquirySummary = (inquiryId?: string) => {
  const { activeCenterId } = useAccessControl();
  const { getToken } = useAccessToken();

  return useQuery({
    queryKey: inquiryQueryKeys.detail(activeCenterId, inquiryId),
    enabled: Boolean(inquiryId),
    queryFn: async () => {
      invariant(inquiryId, "inquiryId is required");
      return getInquiry(await getToken(), activeCenterId, inquiryId);
    },
    select: data => {
      const summary: Inquiry = {
        id: data.id,
        familyId: +data.familyId,
        studentId: data.student.id,
        studentName: data.student.displayName,
        stage: data.inquiryStage as InquiryStage,
        status: data.inquiryStatus,
        statusStartDate: "",
        stageStartDate: "",
        service: data.service.value.toString(),
        isProcessingStatus: data.isProcessingStatus
      };
      return summary;
    }
  });
};

export const useCreateOrUpdateInquiry = () => {
  const { activeCenterId } = useAccessControl();
  const { getToken } = useAccessToken();
  const queryClient = useQueryClient();
  const { OTHER_VALUE } = useFamilyConstants();
  const inquiryConstantsQuery = useInquiryConstants();

  return useMutation({
    mutationFn: async ({ inquiryId, formData }: { inquiryId?: string; formData: InquiryFormData }) => {
      const discountTypeMapping: Record<string, string> = {
        $: "Dollar",
        "%": "Percent"
      };
      const formDataWithDefaults = {
        ...formData,
        relationshipId: formData.relationshipId || `${OTHER_VALUE}`,
        aeFee: {
          ...formData.aeFee,
          discountTypeId: `${
            inquiryConstantsQuery.data?.discountTypes.find(
              ({ name }) => name === discountTypeMapping[formData.aeFee.discountType]
            )?.value ?? ""
          }`
        }
      };

      return inquiryId
        ? updateInquiry(await getToken(), activeCenterId, inquiryId, formDataWithDefaults)
        : createInquiry(await getToken(), activeCenterId, formDataWithDefaults);
    },
    onSuccess: payload => {
      queryClient.invalidateQueries({ queryKey: inquiryQueryKeys.list({ centerId: activeCenterId }) });
      queryClient.invalidateQueries({ queryKey: inquiryQueryKeys.baseDashboard() });
      queryClient.invalidateQueries({
        queryKey: inquiryQueryKeys.detail(activeCenterId, payload.id)
      });
    }
  });
};

export const getInquiry = (accessToken: string, centerId: string, inquiryId: string) => {
  return callLcosService<InquiryResponse>(accessToken, `/api/inquiries/${centerId}/${inquiryId}`);
};

export const createInquiry = (accessToken: string, centerId: string, payload: InquiryFormData) => {
  return callLcosService<InquiryPayload>(accessToken, `/api/inquiries/${centerId}`, "POST", payload);
};

export const updateInquiry = (accessToken: string, centerId: string, inquiryId: string, payload: InquiryFormData) => {
  return callLcosService<InquiryPayload>(accessToken, `/api/inquiries/${centerId}/${inquiryId}`, "PUT", payload);
};
