import { useEffect, useState, useRef } from "react";

import { Message, useWebSocket } from "./use-web-socket";
import { Inquiry, InquiryListRequestParams } from "../use-inquiries";
import { useQueryClient } from "@tanstack/react-query";
import { useAccessControl } from "../../app/use-access-control";
import { inquiryQueryKeys } from "../use-inquiries/query-keys";

// Set difference should work in chrome v 122 and later
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/difference
// custom polyfill just in case difference doesn't exist:
if (!Set.prototype.difference) {
  // eslint-disable-next-line no-extend-native
  Set.prototype.difference = function (otherSet) {
    if (!(otherSet instanceof Set)) {
      throw new TypeError("Argument must be a Set");
    }
    const result = new Set();
    for (const value of this) {
      if (!otherSet.has(value)) {
        result.add(value);
      }
    }
    return result;
  };
}

export const useInquiryStatus = (
  inquiries: Inquiry[],
  queryKey: readonly ["inquiries", "list", Partial<InquiryListRequestParams>]
) => {
  const [inquiryRecord, setInquiryRecord] = useState<Record<string, Inquiry>>({});
  const prevInquiryIds = useRef<Set<string>>(new Set());
  const queryClient = useQueryClient();
  const { activeCenterId } = useAccessControl();

  /**
   * Handles incoming WebSocket message and updates the inquiry record.
   * @param {Object} message - The incoming message from the WebSocket.
   */
  const onMessage = ({ id, data }: Message) => {
    const prevStage = inquiryRecord[id].stage;
    const prevPending = inquiryRecord[id].isProcessingStatus;

    // update inquiry record status
    setInquiryRecord((prevInquiryRecord: Record<string, Inquiry>) => ({
      ...prevInquiryRecord,
      [id]: {
        ...prevInquiryRecord[id],
        isStatusPending: data.is_processing_stage,
        stage: data.stage,
        stageStartDate: data.stage_start_date,
        status: data.status,
        statusStartDate: data.status_start_date
      }
    }));
    // invalidate/refetch pending status
    if (prevPending !== data.is_processing_stage) {
      queryClient.invalidateQueries({ queryKey });
    }

    // invalidate/refetch left hand nav bar numbers
    if (prevStage !== data.stage) {
      const defaultQueryKey = inquiryQueryKeys.list({
        centerId: activeCenterId,
        search: "",
        stages: [],
        sortBy: "",
        limit: 10,
        offset: 0
      });
      queryClient.invalidateQueries({
        queryKey: defaultQueryKey
      });
    }
  };
  const { batchSubUnsub } = useWebSocket(onMessage);

  useEffect(() => {
    // subscribe and unsubscribe to resource updates
    const currentInquiryIds = new Set(inquiries.map(inquiry => inquiry.id));
    const newIds = [...currentInquiryIds.difference(prevInquiryIds.current)];
    const removeIds = [...prevInquiryIds.current.difference(currentInquiryIds)];
    batchSubUnsub({ subscribe: newIds, unsubscribe: removeIds });
    prevInquiryIds.current = currentInquiryIds;

    const newInquiryRecord = inquiries.reduce((acc: Record<string, Inquiry>, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
    setInquiryRecord(newInquiryRecord);

    // note: not using cleanup w/ the useEffect return here to limit subscribe/unsubscribe messages to websocket
  }, [inquiries]);

  const isStatusPending = (inquiryId: string) => () => {
    return Boolean(inquiryRecord[inquiryId]?.isProcessingStatus);
  };

  return {
    isStatusPending
  };
};
