import { useQuery } from "@tanstack/react-query";

import { callLcosService } from "../../data/lcosServices";
import { familyQueryKeys } from "./query-keys";
import { type FamilyConstants } from "./types";
import { useAccessToken } from "../use-access-token";
import { EXTENDED_CACHE_TIME, referenceRecordsToLookup, referenceRecordToCodeLookup } from "../use-reference-data";
import { CODES } from "../../data/dynamics-codes";

export const useFamilyConstants = () => {
  const { getToken } = useAccessToken();

  const query = useQuery({
    queryKey: familyQueryKeys.constants(),
    queryFn: async () => getFamiliesConstants(await getToken()),
    cacheTime: EXTENDED_CACHE_TIME,
    staleTime: EXTENDED_CACHE_TIME,
    select: data => {
      const RELATIONSHIP_LOOKUP = referenceRecordToCodeLookup(data.relationships);
      const EMERGENCY_CONTACT_VALUE =
        (RELATIONSHIP_LOOKUP[CODES.RELATIONSHIPS.EMERGENCY_CONTACT]?.value as string) ?? "";
      const OTHER_VALUE = (RELATIONSHIP_LOOKUP[CODES.RELATIONSHIPS.OTHER]?.value as string) ?? "";
      const STUDENT_VALUE = (RELATIONSHIP_LOOKUP[CODES.RELATIONSHIPS.STUDENT]?.value as string) ?? "";

      return {
        familyConstants: data,
        familyRoleLookup: referenceRecordsToLookup(data.roles),
        familyRelationshipLookup: referenceRecordsToLookup(data.relationships),
        roles: data.roles || [],
        activeRoles: data.roles?.filter(({ isActive }) => isActive) || [],
        relationships: data.relationships || [],
        activeRelationships:
          data.relationships?.filter(({ value, isActive }) => isActive && value !== EMERGENCY_CONTACT_VALUE) || [],
        titles: data.titles || [],
        activeTitles: data.titles?.filter(({ isActive }) => isActive) || [],
        genders: data.genders || [],
        activeGenders: data.genders?.filter(({ isActive }) => isActive) || [],
        activeGrades: data.grades?.filter(({ isActive }) => isActive) || [],
        activePreferredContactMethods: data.preferredContactMethods?.filter(({ isActive }) => isActive) || [],
        gradeLookup: referenceRecordsToLookup(data.grades),
        genderLookup: referenceRecordsToLookup(data.genders),
        EMERGENCY_CONTACT_VALUE,
        OTHER_VALUE,
        STUDENT_VALUE
      };
    }
  });

  const defaults = {
    familyConstants: {} as FamilyConstants,
    familyRoleLookup: {} as Record<string, string>,
    familyRelationshipLookup: {} as Record<string, string>,
    roles: [],
    relationships: [],
    activeRelationships: [],
    titles: [],
    genders: [],
    activeGrades: [],
    gradeLookup: {} as Record<string, string>,
    genderLookup: {} as Record<string, string>,
    EMERGENCY_CONTACT_VALUE: "",
    PARENT_GUARDIAN_VALUE: "",
    STUDENT_VALUE: ""
  };

  return {
    ...defaults,
    ...query.data,
    isLoading: query.isLoading
  };
};

const getFamiliesConstants = (accessToken: string) => {
  return callLcosService<FamilyConstants>(accessToken, `/api/families/constants`, "GET");
};
