import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import moment from "moment-timezone";
import { useEffect, useMemo, useState, type FC } from "react";
import { Controller, useForm, useWatch, type Control, type FieldValues, type UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { useAccessControl } from "../../app/use-access-control";
import { CalendarController } from "../../components/calendar/calendar";
import { consolidateDateRanges } from "../../components/calendar/helper";
import type { DateRangeInfo, DateTimeRange, DatesOfInterest } from "../../components/calendar/types";
import { FormHelpers } from "../../components/forms";
import { InputField } from "../../components/forms/input-field";
import { SelectField } from "../../components/forms/select-field";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { LoadingOverlay } from "../../components/loading-overlay/loading-overlay";
import { useFormModal } from "../../components/modal/modal";
import { MultiSelect } from "../../components/search-combobox/multi-select";
import { MultiSelectSearch } from "../../components/search-combobox/multi-select-search";
import { CODES } from "../../data/dynamics-codes";
import { useCenterConstants } from "../../hooks/use-center";
import { useDebounce } from "../../hooks/use-debounce";
import type { InquiryAppointment } from "../../hooks/use-inquiries";
import {
  useInquiry,
  useInquiryAppointmentStaff,
  useInquiryAppointments,
  useInquiryConstants
} from "../../hooks/use-inquiries";
import { useStaffAvailability } from "../../hooks/use-staff";
import type { BasicFormProps } from "../form-context-wrapper/form-context-wrapper";

export const appointmentFormSchema = z.object({
  id: z.number().optional(),
  type: z.coerce.string().refine(val => val, { message: "Select an appointment type" }),
  service: z.coerce.string().refine(val => val, { message: "Select a service" }),
  location: z.coerce.string().refine(val => val, { message: "Select a location" }),
  test: z.string().optional(),
  selectedDay: z.coerce
    .date()
    .optional()
    .refine(val => val !== undefined, { message: "Select a day" }),
  selectedDateTime: z
    .string()
    //for some reason after validating the first time, value is set to null (probably because there is no time selected i.e. we dont have a "no selection option")
    .nullable()
    //so make it nullable and validate against it
    .refine(val => val, { message: "Select a time" }),
  selectedAttendees: z
    .array(z.object({ value: z.number().positive(), label: z.string().optional() }))
    .refine(val => val.length > 0, { message: "Select an attendee" }),
  selectedStaff: z
    .array(z.object({ value: z.coerce.string(), label: z.string().optional() }))
    .refine(val => val.length > 0, { message: "Select a staff member" })
});

export type AppointmentFormValues = z.infer<typeof appointmentFormSchema>;

export const AppointmentForm: FC<
  BasicFormProps<AppointmentFormValues> & {
    attendees: { value: string | number; label: string; isStudent?: boolean }[];
  }
> = ({ attendees, methods }) => {
  const {
    control,
    register,
    formState: { errors },
    setValue
  } = methods ?? ({ formState: {} } as UseFormReturn<AppointmentFormValues>);
  const { activeCenterId, hasFeatureAccess } = useAccessControl();
  const { activeConstants } = useCenterConstants(activeCenterId);
  const {
    chainableFieldsDict: { appointmentTypesDict, servicesDict },
    activeServices,
    activeAppointmentLocations,
    timezone,
    testDurations,
    appointmentTypeCodeLookup
  } = activeConstants;
  const appointmentFormWatchType = useWatch({ control, name: "type" });
  const appointmentFormWatchService = useWatch({ control, name: "service" });
  const appointmentFormWatchLocation = useWatch({ control, name: "location" });
  const appointmentFormWatchSelectedStaff = useWatch({ control, name: "selectedStaff" });
  const appointmentFormWatchSelectedDay = useWatch({ control, name: "selectedDay" });
  const inquiryTestWatch = useWatch({ control, name: "test" });
  const isAcademicEvalSelected =
    appointmentFormWatchType &&
    appointmentTypeCodeLookup[CODES.APPOINTMENT_TYPES.ACADEMIC_EVAL]?.value === appointmentFormWatchType;

  const [startDateString, setStartDateString] = useState("");
  const [endDateString, setEndDateString] = useState("");
  const [staffSearchValue, setStaffSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(staffSearchValue, 350);

  const inquiryAppointmentStaffQuery = useInquiryAppointmentStaff(activeCenterId, {
    type_id: appointmentFormWatchType ? [appointmentFormWatchType] : [],
    service_id: appointmentFormWatchService ? [appointmentFormWatchService] : [],
    location_id: appointmentFormWatchLocation ? [appointmentFormWatchLocation] : []
  });

  useEffect(() => {
    const filteredStaff = appointmentFormWatchSelectedStaff.filter(
      staff => (staff.value && inquiryAppointmentStaffQuery.data?.some(queryData => queryData.id === staff.value)) ?? []
    );
    setValue("selectedStaff", filteredStaff);
  }, [
    appointmentFormWatchType,
    appointmentFormWatchService,
    appointmentFormWatchLocation,
    inquiryAppointmentStaffQuery.data
  ]);

  useEffect(() => {
    const student = attendees.find(attendee => attendee.isStudent) as { label: string; value: number };
    setValue("selectedAttendees", [...(student && isAcademicEvalSelected ? [student] : [])]);
  }, [appointmentFormWatchType]);

  const staffIds = useMemo(() => {
    if (hasFeatureAccess?.("appointments_any_staff")) {
      const selected = appointmentFormWatchSelectedStaff;
      const staffData = inquiryAppointmentStaffQuery?.data;
      if ((staffData?.length && selected.length === 0) || (selected.length > 1 && selected.at(-1)?.value === "")) {
        setValue("selectedStaff", [{ value: "", label: "Any Staff Member" }]);
      } else if (selected.length > 1 && selected[0].value === "") {
        setValue(
          "selectedStaff",
          selected.filter(staff => staff.value)
        );
      }
    }

    return appointmentFormWatchSelectedStaff.map(staff => staff.value).filter(Boolean);
  }, [appointmentFormWatchSelectedStaff]);

  const staffAvailabilityQuery = useStaffAvailability(activeCenterId, {
    staffIds:
      staffIds[0] === "" ? inquiryAppointmentStaffQuery.data?.map(staff => staff.id).filter(staff => staff) : staffIds,
    appointmentTypeId: appointmentFormWatchType,
    startDatetime: startDateString,
    endDatetime: endDateString,
    allAvailability: staffIds[0] === ""
  });

  const durationMinutes = useMemo(() => {
    if (!appointmentFormWatchType || !appointmentFormWatchService || !appointmentFormWatchLocation) {
      return 0;
    }

    if (inquiryTestWatch) {
      return testDurations[inquiryTestWatch] ?? 180;
    }

    return (
      activeConstants.bookingsAppointmentDurations?.[appointmentFormWatchService]?.[appointmentFormWatchType]?.[
        appointmentFormWatchLocation
      ] || 0
    );
  }, [
    activeConstants,
    appointmentFormWatchType,
    appointmentFormWatchService,
    appointmentFormWatchLocation,
    inquiryTestWatch
  ]);

  const datesOfInterest: DatesOfInterest = useMemo(() => {
    const defaultStart = new Date("1000-01-01T00:00:00");
    const defaultEnd = new Date("3000-01-01T00:00:00");
    const dateRanges: DateRangeInfo[] = consolidateDateRanges(staffAvailabilityQuery.data ?? []).map(range => {
      return { range: range };
    });

    // We can remove this after we verify round robin is working
    if (!appointmentFormWatchSelectedStaff.length) {
      return {
        available: {
          dateRanges: [],
          legendKey: {
            tooltipText: "Available",
            isSelectable: true
          }
        },
        default: {
          dateRanges: [{ range: { startDateTime: defaultStart, endDateTime: defaultEnd } as DateTimeRange }],
          legendKey: {
            className: "nohours",
            tooltipText: "Unavailable",
            isSelectable: false
          }
        }
      };
    }

    return {
      available: {
        // we may need to do some additional logic here
        dateRanges: dateRanges,
        legendKey: {
          tooltipText: "Available",
          isSelectable: true
        }
      },
      default: {
        dateRanges: [{ range: { startDateTime: defaultStart, endDateTime: defaultEnd } as DateTimeRange }],
        legendKey: {
          className: "nohours",
          tooltipText: "Unavailable",
          isSelectable: false
        }
      }
    };
  }, [staffAvailabilityQuery.data, appointmentFormWatchSelectedStaff]);

  const availableDateTimes = useMemo(() => {
    // assuming that availabilty will be per day (noone works from 5pm to 9am);
    const availableDateTimeRanges = staffAvailabilityQuery.data;

    if (!appointmentFormWatchSelectedDay || !availableDateTimeRanges) {
      return [];
    }

    const startOfSelectedDay = moment(appointmentFormWatchSelectedDay).tz(timezone).startOf("day");
    const endOfSelectedDay = moment(appointmentFormWatchSelectedDay).tz(timezone).endOf("day");

    const filteredDateRanges: DateTimeRange[] = [];
    for (const { startDateTime, endDateTime } of availableDateTimeRanges) {
      if (startOfSelectedDay > moment(endDateTime) || endOfSelectedDay < moment(startDateTime)) {
        continue;
      }
      filteredDateRanges.push({ startDateTime, endDateTime });
    }

    const availableDateTimes: Date[] = [];
    filteredDateRanges.forEach(({ startDateTime, endDateTime }) => {
      const currentAppointmentStartDateTime = new Date(startDateTime);
      if (durationMinutes > 0) {
        do {
          const currentMinutes = currentAppointmentStartDateTime.getMinutes();
          const currentAppointmentEndDateTime = new Date(currentAppointmentStartDateTime);
          currentAppointmentEndDateTime.setMinutes(currentMinutes + durationMinutes);

          if (
            currentAppointmentEndDateTime <= endDateTime &&
            !availableDateTimes.some(date => date.getTime() === currentAppointmentStartDateTime.getTime())
          ) {
            availableDateTimes.push(new Date(currentAppointmentStartDateTime));
          }

          currentAppointmentStartDateTime.setMinutes(currentMinutes + durationMinutes);
        } while (currentAppointmentStartDateTime < endDateTime);
      }
    });

    availableDateTimes.sort((d1, d2) => d1.getTime() - d2.getTime());
    return availableDateTimes;
  }, [appointmentFormWatchSelectedDay, staffAvailabilityQuery.data]);

  useEffect(() => {
    setValue("selectedDateTime", "");
  }, [appointmentFormWatchSelectedDay]);

  useEffect(() => {
    // @ts-ignore ignore typescript error (refine doesnt allow values to be set as undefined)
    setValue("selectedDay", undefined);
  }, [appointmentFormWatchSelectedStaff]);

  const handleShownDatesChange = (startDate: Date, endDate: Date) => {
    const today = new Date();

    setStartDateString(
      moment(today > startDate ? today : startDate)
        .tz(timezone)
        .startOf("day")
        .toISOString()
    );
    setEndDateString(moment(endDate).tz(timezone).endOf("day").toISOString());
  };

  return (
    <>
      <div className="row form-standard">
        <div className="col col-12 col-md-6 col-lg-4">
          <SelectField label="Service" error={errors.service} isRequired {...register("service")} disabled>
            <option value="">Select Service</option>
            {activeServices.map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </SelectField>
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <SelectField label="Type" error={errors.type} isRequired {...register("type")}>
            <option value="">Select Type</option>
            {(servicesDict[appointmentFormWatchService]?.appointmentTypes ?? []).map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </SelectField>
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <SelectField label="Location" error={errors.location} isRequired {...register("location")}>
            <option value="">Select Location</option>
            {(appointmentTypesDict[appointmentFormWatchType]?.locations ?? [])
              .filter(location => activeAppointmentLocations.some(({ value }) => value === location.value))
              .map(({ name, value }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
          </SelectField>
        </div>

        <div className="col col-12 col-md-4">
          <Controller
            control={control}
            name="selectedStaff"
            render={({ field: { value, onChange }, fieldState }) => (
              <MultiSelectSearch
                label="Staff"
                selectedValues={value.map(value => value.value)}
                onChangeSelectedItems={onChange}
                options={(inquiryAppointmentStaffQuery.data || []).map(member => ({
                  value: member.id,
                  label: member.displayName
                }))}
                filteredOptions={(inquiryAppointmentStaffQuery.data || [])
                  .filter(member => member.displayName.toLowerCase().includes(debouncedSearchValue.toLowerCase()))
                  .map(member => ({ value: member.id, label: member.displayName }))}
                inputValue={staffSearchValue}
                onChangeInputValue={setStaffSearchValue}
                error={fieldState.error}
                isRequired
              />
            )}
          />
        </div>
        <div className="col col-12 col-md-4">
          <Controller
            control={control}
            name="selectedAttendees"
            render={({ field: { value, onChange }, fieldState }) => (
              <MultiSelect
                label="Attendees"
                selectedValues={value.map(value => value.value)}
                onChangeSelectedItems={onChange}
                options={
                  isAcademicEvalSelected
                    ? attendees
                        .map(attendee => ({
                          ...attendee,
                          disabled: attendee.isStudent
                        }))
                        .sort((a, b) => {
                          if (a.isStudent) {
                            return -1;
                          }
                          if (b.isStudent) {
                            return 1;
                          }
                          return 0;
                        })
                    : attendees
                }
                error={fieldState.error}
                isRequired
              />
            )}
          />
        </div>
        <div className="col col-12 col-md-4">
          <InputField label="Duration" value={durationMinutes} disabled readOnly />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col col-12">
          <h3>
            Available Dates & Times{" "}
            <span className="required" aria-hidden="true">
              *
            </span>
          </h3>
          <p>Complete the options above to see available dates and times.</p>
        </div>

        <div className="col col-12 col-md-6 col-lg-5">
          <LoadingOverlay loading={staffAvailabilityQuery.isFetching}>
            <CalendarController
              compact
              shaded
              datesOfInterest={datesOfInterest}
              control={control as unknown as Control<FieldValues>}
              onShownDatesChange={handleShownDatesChange}
              name="selectedDay"
            />
          </LoadingOverlay>
        </div>
        <div className="col col-12 col-md-6 col-lg-7">
          {!!appointmentFormWatchSelectedDay && (
            <>
              <h4>Available Times {moment(appointmentFormWatchSelectedDay).tz(timezone).format("M/D/yyyy")}</h4>
              <div
                className={classNames("grid-inline available-times", {
                  "is-invalid": errors.selectedDateTime
                })}
              >
                {availableDateTimes.map((dateTime, index) => (
                  <div key={dateTime.toISOString()}>
                    <input
                      type="radio"
                      className="btn-check"
                      id={`outlined-${index}`}
                      value={dateTime.toISOString()}
                      {...register("selectedDateTime")}
                    />
                    <label className="btn btn-outline-success" htmlFor={`outlined-${index}`}>
                      {FormHelpers.isoDateTimeStringToTimeString(dateTime.toISOString(), timezone)}
                    </label>
                  </div>
                ))}
              </div>
              {!!errors?.selectedDateTime?.message && (
                <div className="invalid-feedback">{errors.selectedDateTime.message}</div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const useAppointmentForm = () =>
  useForm<AppointmentFormValues>({
    defaultValues: {
      type: "",
      service: "",
      location: "",
      test: "",
      selectedDay: undefined,
      selectedDateTime: "",
      selectedAttendees: [],
      selectedStaff: []
    },
    resolver: zodResolver(appointmentFormSchema)
  });

interface UseAppoinmentFormModalProps {
  appointment?: InquiryAppointment;
  inquiryId: string;
  centerId: string;
  attendees: { value: number; label: string }[];
}

export const useAppointmentFormModal = ({
  appointment,
  inquiryId,
  centerId,
  attendees
}: UseAppoinmentFormModalProps) => {
  const { createEventMutator, updateEventMutator } = useInquiryAppointments({ inquiryId, centerId });
  const inquiryConstantsQuery = useInquiryConstants();
  const inquiryQuery = useInquiry(inquiryId);
  const {
    APPOINTMENT_STATUS_CANCELLED_ID = "",
    APPOINTMENT_STATUS_SCHEDULED_ID = "",
    APPOINTMENT_CANCEL_REASON_RESCHEDULED_ID = ""
  } = inquiryConstantsQuery.data ?? {};
  const student = inquiryQuery.data?.student;

  const handleSubmit = ({
    id,
    type,
    selectedAttendees,
    selectedStaff,
    service,
    location,
    selectedDateTime
  }: AppointmentFormValues) => {
    if (id) {
      updateEventMutator.mutate(
        {
          id,
          typeId: type,
          bookingsId: "",
          locationId: location,
          scheduledDateTime: selectedDateTime as string,
          statusId: APPOINTMENT_STATUS_CANCELLED_ID,
          serviceId: service,
          staffMembers: selectedStaff.map(({ value }) => value).filter(value => value),
          attendees: selectedAttendees.map(({ value }) => value),
          reasonToCancelId: APPOINTMENT_CANCEL_REASON_RESCHEDULED_ID
        },
        {
          onSuccess: hide
        }
      );
    } else {
      createEventMutator.mutate(
        {
          typeId: type,
          bookingsId: "",
          locationId: location,
          scheduledDateTime: selectedDateTime as string,
          statusId: APPOINTMENT_STATUS_SCHEDULED_ID,
          serviceId: service,
          staffMembers: selectedStaff.map(({ value }) => value).filter(value => value),
          attendees: selectedAttendees.map(({ value }) => value)
        },
        {
          onSuccess: hide
        }
      );
    }
  };

  const { renderModal, show, hide, formId, methods } = useFormModal({
    name: "appointment-form",
    useFormProps: {
      defaultValues:
        appointment !== undefined
          ? {
              id: appointment.id,
              type: appointment.typeId,
              location: appointment.locationId,
              service: appointment.serviceId,
              test: inquiryQuery.data?.testId,
              selectedDay: new Date(appointment.scheduledDateTime),
              selectedDateTime: appointment.scheduledDateTime,
              selectedAttendees: appointment.attendees.map(attendee => ({
                value: attendee.id,
                label: attendee.displayName
              })),
              selectedStaff: appointment.staffMembers.map(staff => ({ value: staff.id, label: staff.displayName }))
            }
          : {
              type: "",
              service: inquiryQuery.data?.serviceId,
              location: "",
              test: inquiryQuery.data?.testId,
              selectedDay: undefined,
              selectedDateTime: "",
              selectedAttendees: [],
              selectedStaff: []
            },

      resolver: zodResolver(appointmentFormSchema),
      mode: "onTouched"
    },
    onSubmit: handleSubmit
  });

  useEffect(() => {
    methods.reset(
      appointment !== undefined
        ? {
            id: appointment.id,
            type: appointment.typeId,
            location: appointment.locationId,
            service: appointment.serviceId,
            test: inquiryQuery.data?.testId,
            selectedDay: new Date(appointment.scheduledDateTime),
            selectedDateTime: appointment.scheduledDateTime,
            selectedAttendees: appointment.attendees.map(attendee => ({
              value: attendee.id,
              label: attendee.displayName
            })),
            selectedStaff: appointment.staffMembers.map(staff => ({ value: staff.id, label: staff.displayName }))
          }
        : {
            type: "",
            service: inquiryQuery.data?.serviceId,
            location: "",
            test: inquiryQuery.data?.testId,
            selectedDay: undefined,
            selectedDateTime: "",
            selectedAttendees: [],
            selectedStaff: []
          }
    );
  }, [appointment, inquiryQuery.data?.serviceId, inquiryQuery.data?.testId]);

  return {
    showAppointmentFormModal: show,
    hideAppointmentFormModal: hide,
    renderAppointmentFormModal: () =>
      renderModal({
        backdrop: "static",
        centered: true,
        size: "lg",
        title: "Create Appointment",
        body: (
          <AppointmentForm
            attendees={attendees.map(attendee => ({ ...attendee, isStudent: attendee.value === student?.id }))}
          />
        ),
        footer: (
          <>
            <button className="btn btn-secondary" onClick={hide}>
              Cancel
            </button>

            <LoadingButton
              className="btn btn-primary"
              type="submit"
              form={formId}
              isLoading={createEventMutator.isLoading || updateEventMutator.isLoading}
              loadingMessage="Saving..."
            >
              Save
            </LoadingButton>
          </>
        )
      })
  };
};
