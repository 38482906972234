import { useEffect, useRef, useState } from "react";
import { getWebSocket } from "./web-socket-singleton";
import { InquiryStage } from "../use-inquiries";

export type Message = {
  id: string;
  data: {
    status: string;
    stage: InquiryStage;
    is_processing_stage: boolean;
    stage_start_date: string;
    status_start_date: string;
  };
};

export const useWebSocket = (onMessage: (message: Message) => void) => {
  const socket = useRef<WebSocket | null>(null); // Persistent WebSocket instance
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const previousResourceIds = useRef<Set<string>>(new Set()); // Track previously subscribed resources
  const messageQueue = useRef<string[]>([]); // store messages in message queue while waiting for socket connection

  useEffect(() => {
    socket.current = getWebSocket();

    // Open connection
    const handleOpen = () => {
      setIsConnected(true);
      while (messageQueue.current.length > 0) {
        const currentMessage = messageQueue.current.shift();
        if (currentMessage) {
          sendMessage(currentMessage);
        }
      }
    };

    const handleError = (error: Event) => {
      console.error("WebSocket error:", error);
    };

    const handleClose = () => {
      setIsConnected(false);
    };

    socket.current.addEventListener("open", handleOpen);
    socket.current.addEventListener("error", handleError);
    socket.current.addEventListener("close", handleClose);

    // Cleanup listeners on unmount
    return () => {
      if (socket.current) {
        socket.current.removeEventListener("open", handleOpen);
        socket.current.removeEventListener("error", handleError);
        socket.current.removeEventListener("close", handleClose);
      }
      // socket.current?.close();
    };
  }, []);

  // separate onMessage handling
  useEffect(() => {
    socket.current = getWebSocket();
    const handleMessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      onMessage(data);
      setMessages(prevMessages => [...prevMessages, data]);
    };
    socket.current.addEventListener("message", handleMessage);
    return () => {
      if (socket.current) {
        socket.current.removeEventListener("message", handleMessage);
      }
    };
  }, [onMessage]);

  const sendMessage = (message: string) => {
    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
      socket.current.send(message);
    } else {
      messageQueue.current.push(message);
    }
  };

  /**
   * Subscribe to a list of resource IDs.
   * @param {string[]} resourceId - The resource id to subscribe to.
   */
  const subscribeToResources = (resourceId: string) => {
    const prevResourceIds = previousResourceIds.current;
    if (!prevResourceIds.has(resourceId)) {
      sendMessage(JSON.stringify({ action: "subscribe", resource_id: resourceId }));
    }

    // Update the current list of subscribed resources
    previousResourceIds.current.add(resourceId);
  };

  /**
   * Unsubscribe from a list of resource IDs.
   * @param {string[]} resourceId - The resource ID to unsubscribe from.
   */
  const unsubscribeFromResources = (resourceId: string) => {
    const currentResourceIds = new Set(resourceId);
    const prevResourceIds = previousResourceIds.current;
    if (prevResourceIds.has(resourceId)) {
      sendMessage(JSON.stringify({ action: "subscribe", resource_id: resourceId }));
    }
    prevResourceIds.forEach((id: string) => {
      if (currentResourceIds.has(id)) {
        socket.current?.send(JSON.stringify({ action: "unsubscribe", resource_id: id }));
        previousResourceIds.current.delete(id);
      }
    });
  };

  /**
   * Unsubscribe from a list of resource IDs.
   * @param {Object} resourceIds - The resource IDs to subscribe to / unsubscribe from.
   * @param {string[]} resourceIds.subscribe - The resource IDs to subscribe to
   * @param {string[]} resourceIds.unsubscribe - The resource IDs to unsubscribe from
   */
  const batchSubUnsub = ({ subscribe, unsubscribe }: { subscribe: string[]; unsubscribe: string[] }) => {
    sendMessage(JSON.stringify({ action: "batch_sub_unsub", subscribe, unsubscribe }));
    subscribe.forEach(id => previousResourceIds.current.add(id));
    unsubscribe.forEach(id => previousResourceIds.current.delete(id));
  };
  return {
    isConnected,
    messages,
    subscribeToResources,
    unsubscribeFromResources,
    batchSubUnsub
  };
};
