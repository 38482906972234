import { type FC } from "react";
import moment from "moment-timezone";
import { InquiryAppointment } from "../../../../hooks/use-inquiries";
import { useAccessControl } from "../../../../app/use-access-control";
import { useCenterConstants } from "../../../../hooks/use-center/use-center-constants";

interface AppointmentDateTimeProps {
  appointment: InquiryAppointment;
}

export const AppointmentDateTime: FC<AppointmentDateTimeProps> = ({ appointment }) => {
  const { activeCenterId } = useAccessControl();

  const {
    activeConstants: { timezone }
  } = useCenterConstants(activeCenterId);

  const { scheduledDateTime, endDateTime } = appointment;
  const dateMoment = moment(scheduledDateTime).tz(timezone);
  const date = dateMoment.format("M/DD/YYYY");
  const dayOfWeek = dateMoment.format("dddd");
  const startTime = dateMoment.format("h:mm a");
  const endTime = moment(endDateTime).tz(timezone).format("h:mm a");
  return (
    <>
      {date} ({dayOfWeek})<br />
      {startTime} - {endTime}
    </>
  );
};
