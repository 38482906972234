const WEBSOCKET_URL = `${process.env.REACT_APP_SW_HOST}/ws/live-resources/`;

let socketInstance: WebSocket;

export const getWebSocket = () => {
  if (!socketInstance || socketInstance.readyState === WebSocket.CLOSED) {
    socketInstance = new WebSocket(WEBSOCKET_URL);
  }
  return socketInstance;
};
