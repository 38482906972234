import { InquiryAppointmentStaffMember, InquiryFormData } from "./types";

export const PRIMARY_CONTACT_VALUE = "primary_contact" as const;

export const inquiryStages = ["AE", "CONS_CONF", "INQ", "ENR", "DROP"] as const;

export const defaultInquiryFormData: InquiryFormData = {
  id: undefined,
  family: undefined,
  familyId: undefined,
  inquirer: undefined,
  student: undefined,
  relationshipId: "",
  serviceId: "",
  programId: "",
  subjectId: "",
  inquiryDate: "",
  inquiryTimeReceived: "",
  sourceId: "",
  referredById: "",
  referredByOther: "",
  aeFee: {
    discountType: "$",
    discountTypeId: "",
    discountValue: "0",
    baseFee: "0",
    discountReasonId: ""
  },
  objections: [],
  standardHourlyTuitionQuoted: "0",
  privateHourlyTuitionQuoted: "0",
  callbackSpecialQuoted: "0",
  durationMinutes: 0,
  notes: "",
  takenBy: undefined
};

export const anyStaffMember: InquiryAppointmentStaffMember = {
  id: "",
  firstName: "Any",
  lastName: "Staff",
  centerId: "",
  displayName: "Any Staff Member",
  businessEmail: ""
};
