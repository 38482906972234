import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAccessControl } from "../../app/use-access-control";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import {
  InquiryListOptions,
  Inquiry,
  GetAllInquiriesResponsePayload,
  inquiryStages,
  InquiryStage,
  InquiryListRequestParams
} from ".";
import { inquiryQueryKeys } from "./query-keys";

export const useInquiryList = (
  { search = "", stages = [], sortBy = "", limit = 10, pageIndex = 0 }: InquiryListOptions = {},
  enabled = true
) => {
  const { getToken } = useAccessToken();
  const { activeCenterId } = useAccessControl();
  const [filteredCountPlaceholder, setFilteredCountPlaceholder] = useState(0);
  const offset = pageIndex * limit;
  const [previousInquiries, setPreviousInquiries] = useState<Inquiry[]>([]);
  const queryKey = inquiryQueryKeys.list({ centerId: activeCenterId, search, stages, sortBy, limit, offset });

  const inquiryQuery = useQuery({
    queryKey: inquiryQueryKeys.list({ centerId: activeCenterId, search, stages, sortBy, limit, offset }),
    queryFn: async () =>
      getAllInquiries(await getToken(), { centerId: activeCenterId, search, stages, sortBy, limit, offset }),
    placeholderData: {
      inquiries: previousInquiries,
      filteredCount: filteredCountPlaceholder,
      stagesCount: Object.fromEntries(
        inquiryStages.map(status => [status, 0] as const)
      ) as GetAllInquiriesResponsePayload["stagesCount"]
    },
    select: useCallback(
      (data: GetAllInquiriesResponsePayload) => ({
        ...data,
        totalPages: Math.ceil(data.filteredCount / limit)
      }),
      [limit]
    ),
    enabled
  });

  useEffect(() => {
    if (inquiryQuery.data) {
      setPreviousInquiries(inquiryQuery.data.inquiries);
    }
  }, [inquiryQuery.data]);

  useEffect(() => {
    setFilteredCountPlaceholder(oldState => inquiryQuery.data?.filteredCount || oldState);
  }, [inquiryQuery.data?.filteredCount]);

  return { ...inquiryQuery, queryKey };
};

export const parseToInquiryStage = (stage: unknown): InquiryStage | null =>
  inquiryStages.includes(stage as InquiryStage) ? (stage as InquiryStage) : null;

const getAllInquiries = async (
  accessToken: string,
  { centerId, search, stages = [], sortBy, limit, offset }: InquiryListRequestParams
) =>
  callLcosService<GetAllInquiriesResponsePayload>(
    accessToken,
    `/api/inquiries/${centerId}?search=${search ?? ""}${stages.map(stage => `&stage=${stage}`).join("")}&sort_by=${
      sortBy ?? ""
    }&limit=${limit ?? ""}&offset=${offset}`,
    "GET"
  );
